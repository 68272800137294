import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"

const StudioStyled = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 7rem 9rem 9rem;
  flex-wrap: wrap;
  ${breakpoint("mobile")`
  padding: 3rem 3rem 9rem;

  `}
  ${breakpoint("tablet")`
  padding: 5rem 7rem 9rem;

  `}
  ${breakpoint("desktop")`
  padding: 7rem 9rem 9rem;

  `}
  video {
    width: 100%;
  }

  form {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    input, textarea, button {
      font-family: warnock-pro-display, serif;
      border: 2px solid black;
      width: 100%;
      max-width: 600px;
      font-size: 1.5rem;
      padding: 0.5rem;
      border-radius: 0;
      background: none;
      appearance: none;
      margin-bottom: 0.5rem;
    }
    textarea {
      height: 200px;
    }
    label {
      display: none;
    }
    button {
      &:hover {
        background: black;
        color: white;
      }
    }
  }
`
const StudioText = styled.div`
  font-size: 1.8rem;
  font-family: warnock-pro-display, serif;
  ${breakpoint("mobile")`
  font-size: 1.3rem;

  `}
  ${breakpoint("tablet")`
  font-size: 1.8rem;

  `}
  ${breakpoint("desktop")`
  font-size: 1.8rem;

  `}
`

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

const Studio = () => {

  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [formSent, setFormSent] = useState(false);
  const [error, setError] = useState(null);



  const data = useStaticQuery(graphql`
    query StudioQuery {
      studio: wordpressPage(slug: {eq: "brand-studio"}) {
        title
        content
        acf {
          video
        }
      }
    }
  `)


  const handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", name, company, email, message })
    })
      .then(() => setFormSent(true))
      .catch(error => setError(error));

    e.preventDefault();
  };

  return (
    <>
      <StudioStyled>
        <video src={ data.studio.acf.video } autoPlay muted loop></video>
        <StudioText
          dangerouslySetInnerHTML={{ __html: data.studio.content }}
        ></StudioText>

        {error &&

          <div>Error: { error }</div>

        }

        {formSent &&

          <div>Thank you for sending.</div>

        }

        {!formSent &&




        <form onSubmit={handleSubmit} name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">

            <label htmlFor="name">
              Name
            </label>
            <input type="text" id="name" placeholder="Name" name="name" required value={name} onChange={e => setName(e.target.value)} />


            <label htmlFor="company">
              Company
            </label>
            <input type="text" id="company" placeholder="Company" name="company" required value={company} onChange={e => setCompany(e.target.value)} />


            <label htmlFor="email">
              Email
            </label>
            <input type="email" id="email"  placeholder="Email" name="email" required value={email} onChange={e => setEmail(e.target.value)} />


            <label htmlFor="message">
              Message
            </label>
            <textarea name="message" id="message" placeholder="Message" required  value={message} onChange={e => setMessage(e.target.value)} />


            <button type="submit">Send</button>

        </form>

      }
      </StudioStyled>
    </>
  )
}

export default Studio
