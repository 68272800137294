import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"

import SEO from "../components/seo"
import Studio from "../components/Studio"

const StudioContent = styled.div`
  width: 800px;
  margin: 200px auto 0;
  video {
    width: 100%;
  }
`

const StudioPage = () => (
  <Layout page="studio">
    <SEO title="Studio" />
    <Studio></Studio>


  </Layout>
)

export default StudioPage
